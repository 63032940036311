import header from './../img/header.png';
import footerLogo from './../img/footerLogo.png';
import footerDiscover from './../img/footerDiscover.png';
import imgTopLeft from './../img/img_top-left.png';
import imgBottomRight from './../img/img_bottom-right.png';
import './Thanks.css';
import React from 'react';

const ThanksScreen = () => {

    return (
        <div className="ThanksContainer">
            <img className="ThanksHeaderImg" src={header} alt="header" />
            <div className="ThanksTitle">Enquête de satisfaction</div>
            <div className="ThanksDescription">Cher Client<br/><br/>Dans le cadre de l'amélioration de nos produits et services, nous avons mis en place cette enquête de satisfaction.<br/>Merci de bien vouloir répondre à ce questionnaire. Promis, cela ne vous prendra pas plus de deux petites minutes ;-)</div>
            <div className="ThanksForm">
                <div className="ThanksContent">
                </div>
                <div className="ThanksBox">

                    <div className="ThanksOverlay">
                        <img className="ImgTopLeft" src={imgTopLeft} alt="imgTopLeft" />
                        <img className="ImgBottomRight" src={imgBottomRight} alt="ImgBottomRight" />
                    </div>
                    <div className="ThanksDescriptionTitle">Merci de votre temps</div>
                    <div className="ThanksDescriptionContent">Avec nos amitiés,<br/>L’équipe Kyranis Travel</div>
                </div>
                <div style={{height: '50px'}}></div>
                <div className="ThanksDescriptionFooter">Ce formulaire a été créé dans Kyranis Travel.<br/>Signaler un cas d'utilisation abusive</div>
            </div>
            <div className="ThanksFooterBorder"></div>
            <img className="ThanksFooterImgLogo" src={footerLogo} alt="header" />
            <img className="ThanksFooterImgLogo" src={footerDiscover} alt="header" />
        </div>
    );
}

export default ThanksScreen;
