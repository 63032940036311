import header from './../img/header.png';
import footerLogo from './../img/footerLogo.png';
import footerDiscover from './../img/footerDiscover.png';
import imgTopLeft from './../img/img_top-left.png';
import imgBottomRight from './../img/img_bottom-right.png';
import './Form.css';
import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from "react-router";
import QuestionRadioGroup from './QuestionRadioGroup'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { firebaseApp, firestore } from '../helpers/firebase'
import useWindowSize from "../hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import { sendMail } from '../helpers/mail';

const Step2Screen = ({history}) => {
    const location = useLocation();

    const { width } = useWindowSize();

    const commentRef = useRef('')
    const [documentId, setDocumentId] = useState(null)

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const [question12, setQuestion12] = useState(-1)
    const [question13, setQuestion13] = useState(-1)
    const [question14, setQuestion14] = useState(-1)
    const [question15, setQuestion15] = useState(-1)
    const [question16, setQuestion16] = useState(-1)
    const [question17, setQuestion17] = useState(-1)

    useEffect(() => {

        const query = new URLSearchParams(window.location.search);
        setDocumentId(query.get('id'))
        if (documentId === null){
            return <Redirect to="/" />;
        }
    }, [documentId])

    const sendButtonClicked = () => {

        setButtonDisabled(true)

        const comment = commentRef?.current?.value
        firestore.collection("users").doc(documentId).update({
            question12,
            question13,
            question14,
            question15,
            question16,
            question17,
            comment,
            date: firebaseApp.firestore.Timestamp.now().toDate(),
        })
        .then(() => {
            setButtonDisabled(false)

            const mailData = {
                ...location?.state,
                question12,
                question13,
                question14,
                question15,
                question16,
                question17,
                comment,
            }
            sendMail(mailData)
            history.push("/thanks")
        })
        .catch((error) => {
            setButtonDisabled(false)
            alert('Une erreur est survenue, merci de réessayer plus')
        });
    }

    const CssTextField = withStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: '#EB1C74',
                },
                '&:hover fieldset': {
                borderColor: '#EB1C74',
                },
                '&.Mui-focused fieldset': {
                 borderColor: '#EB1C74',
                },
            },
        },
      })(TextField);

    const CustomButton = withStyles({
        root: {
            textTransform: 'none',
            width: 150,
            height: 45,
            fontSize: 16,
            color: '#260C70',
            border: '2px solid',
            borderRadius: 5,
            fontFamily: 'Neo Sans Pro Regular',
            backgroundColor: '#FFFFFF',
            borderColor: '#260C70',
            '&:hover': {
                backgroundColor: '#260C70',
                borderColor: '#260C70',
                color: '#FFFFFF',
                boxShadow: 'none',
            },
        },
      })(Button);

      const CustomBackButton = withStyles({
          root: {
              textTransform: 'none',
              width: 150,
              height: 45,
              fontSize: 16,
              color: '#8058C4',
              border: '2px solid',
              borderRadius: 5,
              fontFamily: 'Neo Sans Pro Regular',
              backgroundColor: '#FFFFFF',
              borderColor: '#8058C4',
              '&:hover': {
                  backgroundColor: '#8058C4',
                  borderColor: '#8058C4',
                  color: '#FFFFFF',
                  boxShadow: 'none',
              },
          },
        })(Button);

    return (
        <div className="Container">
            <img className="HeaderImg" src={header} alt="header" />
            <div className="Title">Enquête de satisfaction</div>
            <div className="Description">Cher Client<br/><br/>Dans le cadre de l'amélioration de nos produits et services, nous avons mis en place cette enquête de satisfaction.<br/>Merci de bien vouloir répondre à ce questionnaire. Promis, cela ne vous prendra pas plus de deux petites minutes ;-)</div>
            <div className="Form">
                <div className="Mandatory">*Obligatoire</div>
                <div className="FormCell FormCellNoBorderBottom FormCellRoundTop">
                    <div className="FormLeft"><span className="FormLeftTitle" style={{color: '#260C70'}}>Impression générale</span></div>
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgTopLeft" src={imgTopLeft} alt="imgTopLeft" />
                        </div>
                        <div style={{height: '30px'}}></div>
                        <QuestionRadioGroup question="Votre degré de confiance globale" prefix="Très faible" suffix="Très élevé" color="#260C70" onRadioSelected={(value) => setQuestion12(value)}/>
                        <QuestionRadioGroup question="Votre degré de satisfaction globale" prefix="Très faible" suffix="Très élevé" color="#260C70" onRadioSelected={(value) => setQuestion13(value)}/>
                    </div>
                </div>
                <div className="FormCell FormCellNoBorderBottom FormCellTightTopBottom">
                        <div className="FormLeft">
                            <div className="FormLeftTitle" style={{color: '#8058C4'}}>Traitement de vos réclamations</div>
                            <div className="FormLeftDescription">Si vous aviez émis une réclamation auparavant, son traitement nous importe aussi.<br/><br/>Sinon, merci d'ignorer cette section.</div>
                        </div>
                    <div className="FormRight PaddingLeftRight">
                        <QuestionRadioGroup question="Réactivité pour la prise en compte de votre réclamation" prefix="Insatisfait" suffix="Très satisfait" color="#8058C4" onRadioSelected={(value) => setQuestion14(value)}/>
                        <QuestionRadioGroup question="Réactivité dans le traitement de votre réclamation" prefix="Très long" suffix="Très court" color="#8058C4" onRadioSelected={(value) => setQuestion15(value)}/>
                        <QuestionRadioGroup question="Clarté de nos réponses" prefix="Insatisfait" suffix="Très satisfait" color="#8058C4" onRadioSelected={(value) => setQuestion16(value)}/>
                        <QuestionRadioGroup question="Efficacité dans le traitement de votre réclamation" prefix="Très long" suffix="Très court" color="#8058C4" onRadioSelected={(value) => setQuestion17(value)}/>
                    </div>
                </div>
                <div className="FormCell FormCellRoundBottom FormCellTightTopBottom">
                        <div className="FormLeft">
                            <div className="FormLeftTitle" style={{color: '#EB1C74'}}>Vos suggestions</div>
                            <div className="FormLeftDescription">Des améliorations à nous suggérer ?<br/>Nous sommes preneurs ;-)</div>
                        </div>
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgBottomRight" src={imgBottomRight} alt="imgBottomRight" />
                        </div>
                        <div className="TextFieldForm">
                            <div className="TextFieldTitle" style={{color: '#EB1C74', marginBottom: '20px'}}>Que pourrions-nous faire pour vous rendre la vie plus belle ?</div>
                            <CssTextField className="TextFieldMultiRow" 
                                variant="outlined" 
                                multiline
                                rows={8}
                                placeholder="Votre réponse" 
                                inputRef={commentRef}/>
                            <div style={{height: '50px'}}></div>
                        </div>
                    </div>
                </div>
                <div style={{height: '50px'}}></div>
                <div className="ButtonContainer">
                    <CustomBackButton className="ButtonStyle" variant="outlined" disabled={buttonDisabled} color="primary" onClick={() => window.history.back()} style={{ marginRight: '30px'}}>RETOUR</CustomBackButton>
                    <CustomButton className="ButtonStyle" variant="outlined" disabled={buttonDisabled} color="primary" onClick={(sendButtonClicked)}>ENVOYER</CustomButton>
                </div>
                <div style={{height: '50px'}}></div>
                <div className="DescriptionFooter">Ce formulaire a été créé dans Kyranis Travel.<br/>Signaler un cas d'utilisation abusive</div>
            </div>
            <div className="FooterBorder"></div>
            <img className="FooterImgLogo" src={footerLogo} alt="header" />
            <img className="FooterImgLogo" src={footerDiscover} alt="header" />
        </div>
    );
}

export default Step2Screen;
