import header from './../img/header.png';
import footerLogo from './../img/footerLogo.png';
import footerDiscover from './../img/footerDiscover.png';
import imgTopLeft from './../img/img_top-left.png';
import imgBottomRight from './../img/img_bottom-right.png';
import './Form.css';
import React, { useState, useEffect, useRef } from 'react';
import QuestionRadioGroup from './QuestionRadioGroup'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { firebaseApp, firestore } from '../helpers/firebase'
import useWindowSize from "../hooks/useWindowSize";
import { withStyles } from '@material-ui/core/styles';

const FormScreen = ({history}) => {
    const formRef = useRef(null)

    const { width } = useWindowSize();

    const emailRef = useRef("")
    const nameRef = useRef("")
    const telephoneRef = useRef("")
    const societeRef = useRef("")

    const [emailValid, setEmailValid] = useState(true)
    const [nameValid, setNameValid] = useState(true)
    const [telephoneValid, setTelephoneValid] = useState(true)

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const [question1, setQuestion1] = useState(-1)
    const [question2, setQuestion2] = useState(-1)
    const [question3, setQuestion3] = useState(-1)
    const [question4, setQuestion4] = useState(-1)
    const [question5, setQuestion5] = useState(-1)
    const [question6, setQuestion6] = useState(-1)
    const [question7, setQuestion7] = useState(-1)
    const [question8, setQuestion8] = useState(-1)
    const [question9, setQuestion9] = useState(-1)
    const [question10, setQuestion10] = useState(-1)
    const [question11, setQuestion11] = useState(-1)

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get('email') !== null){
            emailRef.current.value = query.get('email')
        }
    }, [])

    const validateEmail = (email) => {
        const format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return format.test(String(email).toLowerCase());
    }

    const validatePhone = (phone) => {
        const format = /^\d+$/;
        return format.test(String(phone).toLowerCase());
    }

    const sendButtonClicked = () => {

        const email = emailRef?.current?.value
        const name = nameRef?.current?.value
        const telephone = telephoneRef?.current?.value
        const societe = societeRef?.current?.value

        setEmailValid(email.length > 0 && validateEmail(email))
        setNameValid(name.length > 0)
        setTelephoneValid(telephone.length >= 8 && validatePhone(telephone))
    
        if (email.length === 0 || name.length === 0 || telephone.length === 0 || !validateEmail(email) || !validatePhone(telephone)){
            console.log('Missing infos')
            formRef.current.scrollIntoView() 
            return
        }

        setButtonDisabled(true)

        const inputData = {
            form: 'Kyranis',
            email,
            name,
            telephone,
            societe,
            question1,
            question2,
            question3,
            question4,
            question5,
            question6,
            question7,
            question8,
            question9,
            question10,
            question11,
            date: firebaseApp.firestore.Timestamp.now().toDate(),
        }

        firestore.collection("users").add(inputData)
        .then((docRef) => {
            setButtonDisabled(false)
            
            history.push("/step2?id="+docRef.id, inputData)
            return
        })
        .catch((error) => {
            setButtonDisabled(false)
            alert('Une erreur est survenue, merci de réessayer plus')
        });
    }

    const CustomButton = withStyles({
        root: {
            textTransform: 'none',
            width: 150,
            height: 45,
            fontSize: 16,
            color: '#260C70',
            border: '2px solid',
            borderRadius: 5,
            fontFamily: 'Neo Sans Pro Regular',
            backgroundColor: '#FFFFFF',
            borderColor: '#260C70',
            '&:hover': {
                backgroundColor: '#260C70',
                borderColor: '#260C70',
                color: '#FFFFFF',
                boxShadow: 'none',
            },
        },
      })(Button);
    
    return (
        <div className="Container">
            <img className="HeaderImg" src={header} alt="header" />
            <div className="Title">Enquête de satisfaction</div>
            <div className="Description">Cher Client<br/><br/>Dans le cadre de l'amélioration de nos produits et services, nous avons mis en place cette enquête de satisfaction.<br/>Merci de bien vouloir répondre à ce questionnaire. Promis, cela ne vous prendra pas plus de deux petites minutes ;-)</div>
            <div className="Form">
                <div className="Mandatory">*Obligatoire</div>
                <div className="FormCell FormCellNoBorderBottom FormCellRoundTop">
                    
                        <div className="FormLeft" ref={formRef}>
                            <div className="FormLeftTitle">Vos coordonnées</div>
                            <div className="FormLeftDescription">Afin de mieux vous connaître, et pour revenir vers vous en cas de besoin.</div>
                        </div>
                    
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgTopLeft" src={imgTopLeft} alt="imgTopLeft" />
                        </div>
                        <div className="TextFieldForm">
                            <div style={{height: '30px'}}></div>
                            <div className="TextFieldTitle" >Adresse e-mail <span style={{color: '#FF0000'}}>*</span></div>
                            {emailValid ? 
                                <TextField className="TextField" variant="outlined" size="small" inputRef={emailRef}/> : 
                                <TextField error className="TextField" variant="outlined" size="small" inputRef={emailRef}/> 
                            }
                            {!emailValid ? <span className="ErrorMessage">Champ obligatoire ou format incorrect</span> : null}
                            <div className="TextFieldTitle">Votre nom & prénom <span style={{color: '#FF0000'}}>*</span></div>
                            {nameValid ? 
                                <TextField className="TextField" variant="outlined" size="small" inputRef={nameRef}/> :
                                <TextField error className="TextField" variant="outlined" size="small" inputRef={nameRef}/>
                            }
                            {!nameValid ? <span className="ErrorMessage">Champ obligatoire</span> : null }
                            <div className="TextFieldRow">
                                <div className="TextFieldRowColumn1">
                                    <div className="TextFieldTitle">Votre numéro de téléphone <span style={{color: '#FF0000'}}>*</span></div>
                                    {telephoneValid ? 
                                        <TextField className="TextField" variant="outlined" size="small" inputRef={telephoneRef}/> :
                                        <TextField error className="TextField" variant="outlined" size="small" inputRef={telephoneRef}/>
                                    }
                                    {!telephoneValid ? <span className="ErrorMessage">Champ obligatoire ou format incorrect</span> : null }
                                </div>
                                <div className="TextFieldRowColumn2">
                                    <div className="TextFieldTitle">Votre société</div>
                                    <TextField className="TextField" variant="outlined" size="small" inputRef={societeRef}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="FormCell FormCellNoBorderBottom FormCellTightTopBottom">
                    <div className="FormLeft"><span className="FormLeftTitle" style={{color: '#260C70'}}>Accueil téléphonique ou à l'agence</span></div>
                    <div className="FormRight PaddingLeftRight">
                        <QuestionRadioGroup question="Prise en charge" prefix="Insatisfait" suffix="Très satisfait" color="#260C70" onRadioSelected={(value) => setQuestion1(value)}/>
                        <QuestionRadioGroup question="Temps de mise en attente" prefix="Très long" suffix="Très court" color="#260C70" onRadioSelected={(value) => setQuestion2(value)}/>
                        <QuestionRadioGroup question="Amabilité et écoute" prefix="Insatisfait" suffix="Très satisfait" color="#260C70" onRadioSelected={(value) => setQuestion3(value)}/>
                        <QuestionRadioGroup question="Compréhension de votre demande" prefix="Très long" suffix="Très court" color="#260C70" onRadioSelected={(value) => setQuestion4(value)}/>
                        <QuestionRadioGroup question="Qualité de la réponse apportée" prefix="Insatisfait" suffix="Très satisfait" color="#260C70" onRadioSelected={(value) => setQuestion5(value)}/>
                    </div>
                </div>
                <div className="FormCell FormCellNoBorderBottom FormCellTightTopBottom">
                    <div className="FormLeft"><span className="FormLeftTitle" style={{color: '#8058C4'}}>Offre tarifaire</span></div>
                    <div className="FormRight PaddingLeftRight">
                        <QuestionRadioGroup question="Conformité à vos attentes" prefix="Insatisfait" suffix="Très satisfait" color="#8058C4" onRadioSelected={(value) => setQuestion6(value)}/>
                        <QuestionRadioGroup question="Clarté de l’offre" prefix="Insatisfait" suffix="Très satisfait" color="#8058C4" onRadioSelected={(value) => setQuestion7(value)}/>
                        <QuestionRadioGroup question="Délais de réception de l’offre" prefix="Très longs" suffix="Très courts" color="#8058C4" onRadioSelected={(value) => setQuestion8(value)}/>
                    </div>
                </div>
                <div className="FormCell FormCellRoundBottom FormCellTightTopBottom">
                    <div className="FormLeft"><span className="FormLeftTitle" style={{color: '#E72374'}}>Produits & Services</span></div>
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgBottomRight" src={imgBottomRight} alt="imgBottomRight" />
                        </div>
                        <div className="FormRightContent">
                            <QuestionRadioGroup question="Conformité à vos attentes" prefix="Insatisfait" suffix="Très satisfait" color="#E72374" onRadioSelected={(value) => setQuestion9(value)}/>
                            <QuestionRadioGroup question="Rapport qualité/prix" prefix="Insatisfait" suffix="Très satisfait" color="#E72374" onRadioSelected={(value) => setQuestion10(value)}/>
                            <QuestionRadioGroup question="Recommandation à votre entourage" prefix="Jamais" suffix="Certainement" color="#E72374" onRadioSelected={(value) => setQuestion11(value)}/>
                            <div style={{height: '30px'}}></div>
                        </div>
                    </div>
                </div>
                <div style={{height: '50px'}}></div>
                <CustomButton className="ButtonStyle" variant="outlined" disabled={buttonDisabled} color="primary" onClick={sendButtonClicked}>SUIVANT</CustomButton>
                <div style={{height: '50px'}}></div>
                <div className="DescriptionFooter">Ce formulaire a été créé dans Kyranis Travel.<br/>Signaler un cas d'utilisation abusive</div>
            </div>
            <div className="FooterBorder"></div>
            <img className="FooterImgLogo" src={footerLogo} alt="header" />
            <img className="FooterImgLogo" src={footerDiscover} alt="header" />
        </div>
    );
}

export default FormScreen;
